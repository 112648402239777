// ----------------------------------------------------------------------

const universityAccount = {
  displayName: 'Mumbai University',
  email: 'mumbai.university@gmail.com',
  photoURL: '/assets/icons/users/mumbai-university.svg',
  metric: [{ 'Total Students': '5,00,000' }, { 'Completed Mint': '3,00,000' }, { 'Pending Mint': '1,40,000' }, { 'Mint In Process': '10,000' }]
};
const employerAccount = {
  displayName: '',
  // displayName: 'Aiolos Cloud',
  email: 'admin@aiolos.cloud',
  photoURL: '/assets/icons/users/aiolos.svg',
  metric: [{ 'Total Employee': '5,00,000' }, { 'Completed Mint': '3,00,000' }, { 'Pending Mint': '1,40,000' }, { 'Mint In Process': '10,000' }]

};

export {
  universityAccount, employerAccount
}