import React from 'react'
import {
    Typography, Card, Button
} from '@mui/material';
import support from "../assets/svg/support-girl.svg"
import Iconify from './iconify';

const CustomerCare = () => {
    return (
        <Card className='cardMain' sx={{
            // mt: 3,
            // mb: 3,
            // p: 5,
            // pt: 3,
            height: "100%",
            textAlign: "center",
        }} >
            <img src={support} alt="Girl on phone" width={"180px"} style={{ margin: "auto" }} />
            <Typography variant='h5'>
                Any Query?
            </Typography>
            <Button variant="contained" startIcon={<Iconify icon="material-symbols:call" />} sx={{
                mt: 2, backgroundColor: '#5051F9'
            }} >
                Contact Us
            </Button>
        </Card>
    )
}

export default CustomerCare