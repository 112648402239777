import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Grid, TextField, IconButton, InputAdornment, Autocomplete, Popper } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useNavigate } from 'react-router-dom';
// hooks
import useResponsive from '../hooks/useResponsive';

// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
import config from '../config';

// sections
// import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage(props) {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({ email: "", password: "" });
  // const [entities, setEntites] = useState([])


  const reset = () => {
    setData({ email: "", password: "" });
  }
  // useEffect(() => {
  //   // const url = auth.university ? `${config.api.base_url}student-names` : `${config.api.base_url}employee-names`;
  //   const url = `${config.api.base_url}get-entities`;
  //   axios
  //     .get(url, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     })
  //     .then((resp) => {
  //       if (resp.data.success) {
  //         resp = resp.data.data;
  //         // console.log({ resp })
  //         // setEntites(resp)
  //       } else {
  //         console.log('Error while fetching Names');
  //       }
  //     })
  //     .catch((e) => {
  //       console.log('Error while fetching Names');
  //       console.log(e);
  //     });
  // }, [setEntites]);
  return (
    <>
      <Helmet>
        <title> Login | Professional Passport </title>
      </Helmet>

      <StyledRoot>


        {mdUp && (
          <StyledSection>

            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              <Grid sx={{ width: "100%" }}>
                <Logo
                  sx={{
                    // top: { xs: 16, sm: 24, md: 40 },
                    // left: { xs: 16, sm: 24, md: 40 },
                    // maxWidth: "40px"
                    margin: "auto",
                  }} />
              </Grid>

              Hi, Welcome Back
            </Typography>

            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Moderator Log in
            </Typography>

            {/* <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link onClick={handleIsLogginChange} variant="subtitle2">Create one</Link>
            </Typography> */}

            <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
              </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider>

            {/* <LoginForm props={props} handler={props.handler} /> */}
            <Stack spacing={3}>
              <TextField name="email" label="Email address" onChange={(e) => setData({ ...data, email: e.target.value })} />

              <TextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setData({ ...data, password: e.target.value })}
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              {/* <Checkbox name="remember" label="Remember me" /> */}
              <Link variant="subtitle2" underline="hover">
                Forgot password?
              </Link>
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={() => { props.handler(data, true, reset) }}>
              Login
            </LoadingButton>
            <Typography variant="body2" sx={{ mt: 3 }} >
              Go to{" "}
              <Link variant="subtitle2" onClick={() =>
                navigate('/entity/auth', { replace: true })} sx={{ cursor: "pointer" }}>Entity Login</Link>
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }} >
              Go to{" "}
              <Link variant="subtitle2" onClick={() =>
                navigate('/register-user', { replace: true })} sx={{ cursor: "pointer" }}>Register User</Link>
            </Typography>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
