import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import { Grid, Container, styled } from '@mui/material';

import Metrics from "./Metrics";
import Main from "./Main";

import Chart from './Chart';

import "./MasterStyle.css";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const ResponsiveGrid = styled('div')(({ theme }) => ({
    display: "flex",
    gap: "25px",
    "@media (max-width: 1000px)": {
        flexDirection: "column",
        paddingTop: "25px"
    },
    "@media (max-width: 1200px)": {
        paddingTop: "25px"
    }
}));

export default function Home(props) {
    const useWidth = () => {
        const [width, setWidth] = useState(window.innerWidth);
        useEffect(() => {
            const handleResize = () => setWidth(window.innerWidth);
            window.addEventListener("resize", handleResize);
            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }, []);
        return width;
    };
    const width = useWidth();

    return (
        <>
            <Helmet>
                <title> Issue Degree | Home </title>
            </Helmet>

            <Container maxWidth="xl" >
                <ResponsiveGrid width={width}>
                    <Grid flex={2} sx={{ gap: "25px", display: "flex", flexDirection: "column" }} >
                        <Grid>
                            {/* Upper */}
                            <Main {...props} />
                        </Grid>
                        <Grid >
                            {/* lower */}
                            <Chart />
                        </Grid>
                    </Grid>
                    <Grid flex={1} sx={{ minHeight: "100%" }} >
                        {/* side */}
                        <Metrics />
                    </Grid>
                </ResponsiveGrid>
            </Container>
        </>
    );
} 