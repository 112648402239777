import { useState, useEffect } from 'react';
// @mui
import { Grid, Card, Avatar, Typography } from '@mui/material';
import styled from '@emotion/styled';


// sections
import SearchSection from '../SearchSection';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const AvatarContainer = styled(Grid)(({ theme }) => ({
    // display: "flex",
    flex: 3,
    "@media (max-width: 1000px)": {
        flex: 2,

    },
}));
const ResponsiveContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    "@media (max-width: 750px)": {
        flexDirection: "column"
    },
}));
export default function Main(props) {
    return (
        <>
            <ResponsiveContainer container spacing={3} sx={{ display: "flex" }}>
                <AvatarContainer item>
                    <Card className='cardMain' sx={{ minHeight: "100%", display: "flex", alignItems: "center", flexDirection: "column", objectFit: "contain", py: 4 }}>
                        <Avatar src={props.auth.logo} alt="photoURL" sx={{
                            width: "180px", height: "180px", objectFit: "contain !important", bgcolor: "#E4E6EE", p: 3,
                        }}
                            imgProps={{ style: { objectFit: 'contain' } }}
                        />
                        <Typography variant="h6" sx={{ color: 'text.primary', pt: 3 }}>
                            {props.auth.entityName}
                        </Typography>
                    </Card>
                </AvatarContainer>
                <Grid item flex={5}  >
                    <SearchSection
                        {...props}
                        isLoading={props.isLoading}
                        errorMessage={props.errorMessage}
                        isConnected={props.isConnected}
                        successMessage={props.successMessage}
                        type={props.auth.university ? "student" : "employee"}
                        task={props.auth.university ? "degree" : "experience"}
                        mintButtonText={props.auth.university ? 'Grant Degree' : "Grant Experience"}

                    />
                </Grid>
            </ResponsiveContainer>
        </>
    );
}
