import React, { useState } from 'react'
import { Container, Typography, Grid, Button, Select, MenuItem, InputLabel } from '@mui/material/'
import TextField from '@mui/material/TextField';
import * as yup from 'yup'

const BasicDetails = ({ nextStep, initialData, handleChange, values }) => {
  const [errors, setErrors] = useState({});

  const schema = yup.object().shape({
    name: yup.string().required('Full name is required'),
    phone: yup.string()
    .required('Phone number is required')
    .matches('((\\+*)((0[ -]*)*|((91 )*))((\\d{12})+|(\\d{10})+))|\\d{5}([- ]*)\\d{6}', 'Please enter a valid Mobile phone number'),
    email: yup.string().email('Invalid email').required('Email is required'),
  });

  const Continue = async (event) => {
    event.preventDefault();

    try {
      await schema.validate(values, { abortEarly: false });
      nextStep();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      }
    }
  };
  // const Continue = e => {
  //   nextStep();
  // }

  return (
    <Container component="main" maxWidth="xs">
      <div>
        <Typography variant="h2" sx={{ textAlign: "center", mb: 5 }}>
          Basic Details
        </Typography>

        <form >
          <Grid container spacing={2}>
            {/* fullname */}
            <Grid item xs={12}>
              <TextField
                error={!!errors.name}
                helperText={errors.name}
                label="Full Name"
                onChange={handleChange}
                defaultValue={values.name}
                name="name"
                fullWidth
              />
            </Grid>
            <br />

            {/* phone */}
            <Grid item xs={12}>
              <TextField
                error={!!errors.phone}
                helperText={errors.phone}
                label="Phone Number"
                name="phone"
                onChange={handleChange}
                value={values.phone}
                fullWidth
              />
            </Grid>
            <br />

            {/* email */}
            <Grid item xs={12}>
              <TextField
                error={!!errors.email}
                helperText={errors.email}
                label="Email"
                name="email"
                onChange={handleChange}
                value={values.email}
                fullWidth
              />
            </Grid>
            <br />

            {/* submit */}
            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12} sm={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled
                >
                  Previous
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={Continue}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container >
  )
}
export default BasicDetails;