import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';

// import Cookies from 'universal-cookie';
// layouts
import axios from 'axios';
import DashboardLayout from './layouts/dashboard';
// import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import ComingSoon from './pages/ComingSoon';
import Home from './pages/pp/Home';
import HomeNew from './pages/pp/HomeNew/Index';
import EntityAuth from './pages/pp/EntityAuth';
import config from './config';
import { universityAccount, employerAccount } from './_mock/account';

import EmployeeDetails from './pages/pp/FetchData';
import { getBalance, getTokenOfOwnerByIndex, getTokenUri, mintNFTCreds } from './interact';
import Page404 from './pages/Page404';
import MintCertificates from './pages/pp/MintCertiicates';
import MintResult from './pages/pp/MintResult';
import RegisterMod from './pages/pp/RegisterMod';
import RegisterUser from './pages/pp/RegisterUser/RegisterUser';

// ----------------------------------------------------------------------

export default function Router() {
  // const cookies = new Cookies();
  const [auth, setAuth] = useState({
    loggedIn: false, university: false, employer: false, role: ""
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [account, setAccount] = useState(null);
  const [balance, setBalance] = useState(null);
  const navigate = useNavigate();
  const [names, setNames] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // ------------------------ use effects --------------------------
  useEffect(() => {

    const token = localStorage.getItem("token");
    if (!auth.loggedIn && token) {
      axios.get(`${config.api.base_url}auth/token`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((resp) => {
        // console.log({ resp })
        const data = resp.data.data;
        console.log({ data })
        const newAuth = { loggedIn: true, employer: false, university: false, ...data };
        if (data.type === 'university') newAuth.university = true;
        else newAuth.employer = true;

        // console.log({ newAuth, resp })
        setAuth(newAuth);
        localStorage.setItem("auth", JSON.stringify(newAuth));

      }).catch((err) => { console.log(err) })
    }

  }, [auth]);
  useEffect(() => {
    // getting list of names
    if (auth.loggedIn) {
      // const url = auth.university ? `${config.api.base_url}student-names` : `${config.api.base_url}employee-names`;
      const url = `${config.api.base_url}get-names?type=${auth.university ? 'student' : 'employee'}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((resp) => {
          if (resp.data.success) {
            resp = resp.data.data;
            // console.log({ resp })
            setNames(capitalizedNames(resp));
            setSelectedData(null);
          } else {
            console.log('Error while fetching Names');
          }
        })
        .catch((e) => {
          console.log('Error while fetching Names');
          console.log(e);
        });
    }
  }, [auth, setAuth]);

  // ------------------------ Auth --------------------------
  const accountsChanged = async (newAccount) => {
    setAccount(newAccount);
    console.log("metamask account changed ")
    try {
      const balance = await window.ethereum.request({
        method: 'eth_getBalance',
        params: [newAccount.toString(), 'latest'],
      });
      setBalance(ethers.utils.formatEther(balance));
      setErrorMessage();
      setSuccessMessage();
    } catch (err) {
      setAccount(null);
      setIsLoading(false);

      console.error(err);
      setErrorMessage('There was a problem connecting to MetaMask');
    }
  };
  const handleMetamaskLogin = async () => {
    console.log('Conecting to metamask');
    if (window.ethereum) {
      try {
        setErrorMessage(null);
        setSuccessMessage(null);
        const res = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        await accountsChanged(res);
        console.log('Wallet connected Successfully ', res);
        // ToDo - Check who is signing in - University or Employer - By the tokens stored in their address
        setAuth({
          loggedIn: true,
          university: false,
          employer: true,
        });
        console.log('redirecting to university home');
      } catch (err) {
        console.error(err);
        setErrorMessage('There was a problem connecting to MetaMask');
      }
    } else {
      setErrorMessage('Install MetaMask');
    }
  };
  const handleNormalLogin = (data, isloggin, reset) => {
    // console.log(props)
    if (isloggin) {
      axios
        .post(`${config.api.base_url}auth/login`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((resp) => {
          if (resp.data.success) {
            console.log('Correct email and password');
            reset();
            // console.log({ received: resp.data });
            localStorage.setItem("token", resp.data.data.token);
            const data = resp.data.data;
            if (resp.data.data.type === 'university') {
              const newAuth = { loggedIn: true, employer: false, university: true, ...data }
              // localStorage.setItem('loggedIn', 'university');
              // cookies.set('loggedIn', 'university');
              localStorage.setItem('auth', JSON.stringify(newAuth))
              setAuth(newAuth);
              console.log('redirecting to university home');
              navigate('/university', { replace: true });

            } else if (resp.data.data.type === 'employer') {
              const newAuth = { loggedIn: true, employer: true, university: false, ...data }
              // localStorage.setItem('loggedIn', 'employer');
              // cookies.set('loggedIn', 'employer');
              localStorage.setItem('auth', JSON.stringify(newAuth))
              setAuth(newAuth);
              console.log('redirecting to employer home');
              navigate('/employer', { replace: true });
            }

          } else {
            console.log('Incorrect email and password');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(`${config.api.base_url}auth/register`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((resp) => {
          if (resp.data.success) {
            // handleIsLogginChange();
            reset();

            console.log("register success");
          } else {
            console.log('Incorrect email and password');
          }
        })
    }
  };

  const capitalizedNames = (resp) => {
    return resp.map((doc) => {
      let arrayOfwords = doc.name.split(' ');
      arrayOfwords = arrayOfwords.map((word) => word[0].toUpperCase() + word.slice(1));
      const newName = arrayOfwords.join(' ');
      return { ...doc, name: newName };
    });
  };

  const handleMint = async (type, task) => {
    if (!account) {
      await connectHandler();
      return false;
    }
    console.log("selected data",selectedData.wallet_address);
    if (!selectedData) {
      setErrorMessage('Please select a user to start the minting process');
      return false;
    }
    setIsLoading(true);
    // const url = auth.university ? `${config.api.base_url}mint-degree` : `${config.api.base_url}mint-experience`;
    const uploadImage = await axios.post(`${config.api.base_url}mint`, { type, task, data: selectedData });
    if (uploadImage.data.success) {
      const walletAddress = selectedData.wallet_address;
      const CID = uploadImage.data.CID;
      console.log(walletAddress, CID);
      const mintNft = await mintNFTCreds(walletAddress, CID, account[0]);
      if (mintNft) {
        console.log('mintNft', mintNft);
        setSuccessMessage(mintNft.message);
      } else {
        alert('Error');
      }
    } else {
      console.log('Error while minting nft');
    }
    return setIsLoading(false);
  };

  const handleSearch = (e, option) => {
    if (!option) {
      setSelectedData(null);
      setSuccessMessage(null);
      return;
    }
    const id = option._id;
    // const url = auth.university ? `${config.api.base_url}student-details` : `${config.api.base_url}employee-details`;
    const type = auth.university ? 'student' : 'employee';
    const url = `${config.api.base_url}get-details?type=${type}`;
    axios.post(url, { id }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((resp) => {
      if (resp.data.success) {
        let data = resp.data.data;

        if (type === "student") {
          data = { name: data.name, role: data.role, wallet_address: data.wallet_address,degree: data.degree, gpa:data.gpa,joining_date:data.joining_date, graduationDate: data.graduationDate, ...data.education }
        } else {
          data = { name: data.name, role: data.role, wallet_address: data.wallet_address,joining_date:data.joining_date,department: data.department,position: data.position, ...data.experience }

        }

        console.log({ data });
        setSelectedData(capitalizedNames([data])[0]);
        setErrorMessage('');
      } else {
        console.log('Error while fetching student data');
      }
    });
  };

  const handleFetchNfts = async (walletAddress) => {
    // const account = "0x7ddd773dC8b792D86923f7BC80A15997E222E69D";
    const balance = await getBalance(walletAddress);
    console.log({ balance });

    /* eslint-disable*/
    for (let i = 0; i < balance; i++) {
      /* eslint-disable*/
      const globalIndex = await getTokenOfOwnerByIndex(walletAddress, i);
      if (globalIndex) {
        const tokenUri = await getTokenUri(globalIndex);
        // console.log(tokenUri);
      } else {
        console.log('Error while getting globalIndex ', balance);
      }
    }
  };

  //Amin Changes start

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.request({ method: 'eth_accounts' }).then(accountsChanged).catch(console.error);
      window.ethereum.on('accountsChanged', accountsChanged);
      window.ethereum.on('chainChanged', chainChanged);
    }
  }, []);
  const connectHandler = async () => {
    if (window.ethereum) {
      console.log('In window');
      const permissions = await ethereum.request({
        method: 'wallet_requestPermissions',
        params: [
          {
            eth_accounts: {},
          },
        ],
      });
      try {
        setErrorMessage(null);
        setSuccessMessage(null);
        const res = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        await accountsChanged(res);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
        setErrorMessage('There was a problem connecting to MetaMask');
      }
    } else {
      setIsLoading(false);

      setErrorMessage('Install MetaMask');
    }
  };

  const chainChanged = () => {
    setErrorMessage(null);
    setSuccessMessage(null);
    setAccount(null);
    setBalance(null);
  };
  const mintingHandler = async (toMintAddress, tokenUri) => {
    if (account) {
      setErrorMessage();
      setSuccessMessage();
      // console.log('Amin', toMintAddress, tokenUri, account[0]);
      if (balance > 0) {
        setErrorMessage();
        setSuccessMessage();
        const mintCredentials = await mintNFTCreds(toMintAddress, tokenUri, account[0]);
        if (mintCredentials.status === 'success') {
          setErrorMessage();
          setSuccessMessage(mintCredentials.message);
          document.getElementById('surplusBufferForm').reset();
          setTimeout(() => {
            accountsChanged(account);
          }, 3000);
        } else {
          setSuccessMessage();
          setErrorMessage(mintCredentials.message);
        }
      } else {
        setSuccessMessage();
        setErrorMessage("You don't have enough balance to Mint Credentials");
      }
    }
  };
  const handleEntityAuth = (data, isloggin, handleIsLogginChange, reset, selectedLogo) => {
    // console.log({ ...data, logo: selectedLogo })
    if (isloggin) {
      //login
      axios.post(`${config.api.base_url}auth/entity-login`, { ...data }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then((response) => {
        // console.log({ response });
        if (response.data.success) {
          localStorage.setItem("token", response.data.data.token);
          reset();
          const data = response.data.data;
          if (data.type === 'university') {
            const newAuth = { loggedIn: true, employer: false, university: true, ...data }
            // localStorage.setItem('loggedIn', 'university');
            // cookies.set('loggedIn', 'university');
            localStorage.setItem('auth', JSON.stringify(newAuth))
            setAuth(newAuth);
            console.log('redirecting to university home');
            navigate('/university', { replace: true });

          } else if (data.type === 'employer') {
            const newAuth = { loggedIn: true, employer: true, university: false, ...data }
            // localStorage.setItem('loggedIn', 'employer');

            // cookies.set('loggedIn', 'employer');
            localStorage.setItem('auth', JSON.stringify(newAuth))
            setAuth(newAuth);
            console.log('redirecting to employer home');
            navigate('/employer', { replace: true });
          }
        } else {
          console.log('Incorrect email and password');

        }
      }).catch((error) => {
        console.log(error);
      });
    } else {
      //register
      const formData = new FormData();
      formData.append('image', selectedLogo);
      formData.append("data", JSON.stringify(data))
      axios.post(`${config.api.base_url}auth/entity-register`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("token")}`,

        }

      }).then((response) => {
        if (response.data.success) {
          handleIsLogginChange();
        }
        console.log({ response });
      }).catch((error) => {
        console.log(error);
      });
    }
  }
  //Amin changes end
  const routes = useRoutes([
    {
      path: '/university',
      element: auth.loggedIn ? (
        auth.university ? (
          <DashboardLayout auth={auth} setAuth={setAuth} />
        ) : (
          <Navigate to="/employer" />
        )
      ) : (
        <Navigate to="/login" />
      ),
      children: [
        { element: <Navigate to="/university/home" />, index: true },
        {
          path: 'home',
          element: (
            <HomeNew
              auth={auth}
              isLoading={isLoading}
              handleSubmit={handleMint}
              handleSearch={handleSearch}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              names={names}
              isConnected={account ? true : false}
              errorMessage={errorMessage}
              successMessage={successMessage}
              subtitle={"Issue Degree"}
              user={universityAccount}
            />
          ),
        },
        {
          path: 'mint-result', element: <MintResult
            auth={auth}
            isLoading={isLoading}
            handleSubmit={handleMint}
            handleSearch={handleSearch}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            names={names}
            isConnected={account ? true : false}
            errorMessage={errorMessage}
            successMessage={successMessage}
            subtitle={"Mint Semester Results"}
            user={universityAccount} />
        },
        {
          path: 'mint-certificate', element: <MintCertificates auth={auth}
            isLoading={isLoading}
            handleSubmit={handleMint}
            handleSearch={handleSearch}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            names={names}
            isConnected={account ? true : false}
            errorMessage={errorMessage}
            successMessage={successMessage}
            subtitle={"Mint Certificate for student"}
            user={universityAccount}

          />
        },
        {
          path: 'register-mod',
          element: (auth.role === "admin" || auth.role === "superadmin") ? <RegisterMod handler={handleNormalLogin} auth={auth} /> :
            <Navigate to="/university/home" />,

        },
      ],
    },
    {
      path: '/employer',
      element: auth.loggedIn ? (
        auth.employer ? (
          <DashboardLayout auth={auth} setAuth={setAuth} />
        ) : (
          <Navigate to="/university" />
        )
      ) : (
        <Navigate to="/login" />
      ),
      children: [
        { element: <Navigate to="/employer/home" />, index: true },
        {
          path: 'home',
          element: (
            <HomeNew
              auth={auth}
              handleSubmit={handleMint}
              connectHandler={connectHandler}
              handleSearch={handleSearch}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              names={names}
              isConnected={account ? true : false}
              errorMessage={errorMessage}
              isLoading={isLoading}
              successMessage={successMessage}
              subtitle={"Issue Experience certificate to Employee"}
              user={employerAccount}
            />
          ),
        },
        {
          path: 'fetch-data',
          element: (
            <EmployeeDetails
              auth={auth}
              handleSearch={handleSearch}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              names={names}
              account={account}
              handleMetamaskLogin={handleMetamaskLogin}
            />
          ),
        },
        {
          path: 'mint-certificate', element: <MintCertificates auth={auth}
            isLoading={isLoading}
            handleSubmit={handleMint}
            handleSearch={handleSearch}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            names={names}
            isConnected={account ? true : false}
            errorMessage={errorMessage}
            successMessage={successMessage}
            subtitle={"Mint Certificate for employees"}
            user={employerAccount}

          />
        },
        {
          path: 'register-mod',
          element: (auth.role == 'admin' || auth.role == "superadmin") ? <RegisterMod handler={handleNormalLogin} auth={auth} /> :
            <Navigate to="/employer/home" />,

        },
      ],
    },
    {
      path: '/login',
      element: !auth.loggedIn ? (
        <LoginPage handler={handleNormalLogin} connectHandler={connectHandler} />
      ) : (auth.university ?
        <Navigate to="/university" /> : <Navigate to="/employer" />
      ),
    },
    {
      path: '/entity/auth',

      element: !auth.loggedIn ? (

        <EntityAuth handler={handleEntityAuth} connectHandler={connectHandler} />
      ) : (
        <Navigate to="/university/app" />
      ),
    },
    {
      path: '/register-user',
      element: <RegisterUser />,
    },
    {
      path: '/404',
      element: <Page404 />,
    },
    {
      path: '/',
      // element: <Navigate to={auth.loggedIn ? '/university' : '/login'} />,
      element: <Navigate to={auth.loggedIn ? '/university' : '/login'} />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
