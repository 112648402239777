import React, { useState } from 'react';
import { Container, Typography, Grid, Button, Select, MenuItem, } from '@mui/material';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import * as yup from 'yup';

// date
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: "20px"
};

const experienceSchema = yup.object().shape({
  company: yup.string().required('Company is required'),
  position: yup.string().required('Position is required'),
  department: yup.string().required('Department is required'),
  startDate: yup.string().required('Start date is required').nullable(),
  endDate: yup.string().required('End date is required').nullable(),
});

const Experience = ({ prevStep, handleSave, handleSubmit, values, }) => {
  const [errors, setErrors] = useState({});
  const [experienceLetterError, setExperienceLetterError] = useState(null);
  const [certificateError, setCertificateError] = useState(null);
  const [dateError, setDateError] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState({
    company: '',
    position: '',
    startDate: null,
    endDate: null,
    department: '',
    certificate: null,
    experienceLetter: null
  })

  const handleOpen = (index) => {
    setSelectedIndex(index);
    setOpen(true);
    if(index === -1) {
      setData({
        company: '',
        position: '',
        startDate: null,
        endDate: null,
        department: '',
        certificate: null,
        experienceLetter: null
      });
    } else {
      setData({...values[index]});
    }
  }

  const handleClose = () => {
    setOpen(false);
    setSelectedIndex(-1);
    setExperienceLetterError(null)
    setCertificateError(null)
  }
  
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  // const handleDateChange = (date, name) => {
  //   setData({ ...data, [name]: date });
  // };

  // working date
  // const handleDateChange = (date, name) => {
  //   const formattedDate = dayjs(date).format("YYYY MMM");
  //   setData({ ...data, [name]: formattedDate });
  // };

  const handleDateChange = (date, name) => {
    if (name === 'endDate' && dayjs(date).isBefore(dayjs(data.startDate))) {
      setData({ ...data, endDate: null });
      setDateError('End date cannot be earlier than start date');
    } else if (name === 'endDate' && !data.startDate ){
      setData({ ...data, endDate: null });
      setDateError('First Select the Start Date');
    } else {
      const formattedDate = dayjs(date).format("YYYY MMM");
      setData({ ...data, [name]: formattedDate });
      setDateError(null);
    }
  };

  // working file
  // const handleFileChange = (event, name) => {
  //   const file = event.target.files[0];
  //   setData({ ...data, [name]: file });
  // };

  const handleFileChange = (event, name) => {
    const file = event.target.files[0];
    const allowedFileTypes = ["image/jpeg", "image/png"];
    if (allowedFileTypes.includes(file.type)) {
      setData({ ...data, [name]: file });
      if (name === 'experienceLetter') setExperienceLetterError(null);
      if (name === 'certificate') setCertificateError(null);
    } else {
      if (name === 'experienceLetter') setExperienceLetterError(`Only image files are allowed for Experience Letter.`);
      if (name === 'certificate') setCertificateError(`Only image files are allowed for Certificate.`);
    }
  };

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  // const handleClickSave = (e) => {
  //   if (experienceLetterError || certificateError || dateError) {
  //     e.preventDefault()
  //     return;
  //   }
  //   if (selectedIndex === -1) {
  //     values.push(data)
  //   } else {
  //       values[selectedIndex] = data;
  //   }
  //   handleClose();
  // }

  const handleClickSave = async (e) => {
    e.preventDefault();
    try {
      await experienceSchema.validate(data, { abortEarly: false });
      setErrors({});
      if (experienceLetterError || certificateError || dateError) {
            e.preventDefault()
            return;
          }
      if (selectedIndex === -1) {
        values.push(data)
      } else {
        values[selectedIndex] = data;
      }
      handleClose();
    } catch (error) {
      setErrors(error.inner.reduce((acc, curr) => ({ ...acc, [curr.path]: curr.message }), {}));
    }
  }

  const handleDelete = (index) => {
    values.splice(index, 1);
    setData({...values});
    setSelectedIndex(-1);
  }

    const handleImageDelete = (field) => {
    setData({ ...data, [field]: null });
  };

  const Item = ({ data, index, openModal }) => {
    return <Grid sx={{ mb: 3 }}>
      <Typography variant='h4' sx={{ fontSize: "1.05rem !important" }}>{data.company} </Typography>
      <Typography variant='h6'>{data.position}</Typography>
      <Typography variant='subtitle2'>{data.startDate} - {data.endDate} </Typography>
      <Typography variant='subtitle2'>{data.department} </Typography>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        
      {data.experienceLetter && 
      <Grid item xs={12} sm={4}>
      <Box sx={{ width: 100, height: 100, overflow: "hidden" }}>
        <img src={URL.createObjectURL(data.experienceLetter)} alt="" style={{ width: "100%", height: "100%" }} />
      </Box>
      Experience
    </Grid>
      }

      {data.certificate && 
      <Grid item xs={12} sm={4}>
      <Box sx={{ width: 100, height: 100, overflow: "hidden" }}>
        <img src={URL.createObjectURL(data.certificate)} alt="" style={{ width: "100%", height: "100%" }} />
      </Box>
      Certificate
    </Grid>
      }
      </Grid>

      {/* edit delete button */}
      <Button onClick={() => { openModal(index)}}>Edit</Button>
      <Button onClick={() => handleDelete(index)}>Delete</Button>
    </Grid>
  }

  return (
    <Container component="main" maxWidth="xs">
      <div>
        <Typography variant="h2" sx={{ textAlign: "center", mb: 5 }}>
          Experience
        </Typography>
        <Button onClick={() => handleOpen(-1)} sx={{ fontSize: "1.1rem" }}> + Add Previous Experience</Button>
        <Grid>
          {values.map((e, key) => {
            return <Item key={key} data={e} index={key} openModal={handleOpen}/>
          })}
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form>
            <Box sx={style} >
              <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant='h4' sx={{ mb: 3 }}>
                  Add Experience
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    error={!!errors.company}
                    helperText={errors.company}
                    label="Company name"
                    name='company'
                    onChange={handleChange}
                    defaultValue={selectedIndex === -1 ? '' : values[selectedIndex].company}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    error={!!errors.position}
                    helperText={errors.position}
                    label="Position"
                    name='position'
                    onChange={handleChange}
                    defaultValue={selectedIndex === -1 ? '' : values[selectedIndex].position}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    error={!!errors.department}
                    helperText={errors.department}
                    label="Department"
                    name='department'
                    onChange={handleChange}
                    defaultValue={selectedIndex === -1 ? '' : values[selectedIndex].department}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sx={{ display: "flex", gap: "15px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <Grid item xs={12} sm={6} >
                      <DatePicker
                        views={["month", "year"]}
                        label="Start Year"
                        name="startDate"
                        disableFuture
                        value={data.startDate}
                        onChange={(date) => handleDateChange(date, "startDate")}
                        renderInput={(params) => <TextField {...params} required helperText={errors.startDate} error={!!errors.startDate} />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                      <DatePicker
                        views={["month", "year"]}
                        label="End Year"
                        name="endDate"
                        disableFuture
                        value={data.endDate}
                        onChange={(date) => handleDateChange(date, "endDate")}
                        renderInput={(params) => <TextField {...params} required helperText={errors.endDate} error={!!errors.endDate} />}
                      />
                    </Grid>
                  </LocalizationProvider>
                </Grid>

                {dateError && 
                <Grid item xs={12}>
                <p style={{ color: 'red' }}>{dateError}</p>
                </Grid>
                }

                {data.experienceLetter ? 
                  <Grid item xs={12}>
                    <Box sx={{ width: 100, height: 100, overflow: "hidden" }}>
                      <img src={URL.createObjectURL(data.experienceLetter)} alt="" style={{ width: "100%", height: "100%" }} />
                    </Box>
                      Degree
                    <Button onClick={() => handleImageDelete("experienceLetter")}>
                        Remove
                    </Button>
                  </Grid>
                  : <Grid item xs={12}>
                  <TextField
                  label="Experience Letter"
                  type="file"
                  name='experienceLetter'
                  InputLabelProps={{
                    shrink: true,
                    accept: "image/*"
                  }}
                  onChange={(e) => handleFileChange(e, "experienceLetter")}
                />
                  {experienceLetterError && <p style={{ color: 'red' }}>{experienceLetterError}</p>}
                </Grid>
                }

                {/* <Grid item xs={12}>
                  <TextField
                    label="Experience Letter"
                    type="file"
                    name="experienceLetter"
                    InputLabelProps={{
                      shrink: true,
                      accept: "image/*"
                    }}
                    onChange={(e) => handleFileChange(e, "experienceLetter")}
                  />
                  {experienceLetterError && <p style={{ color: 'red' }}>{experienceLetterError}</p>}
                </Grid> */}

                {data.certificate ? 
                  <Grid item xs={12}>
                    <Box sx={{ width: 100, height: 100, overflow: "hidden" }}>
                      <img src={URL.createObjectURL(data.certificate)} alt="" style={{ width: "100%", height: "100%" }} />
                    </Box>
                    Certificate
                    <Button onClick={() => handleImageDelete("certificate")}>
                        Remove
                    </Button>
                  </Grid>
                  : <Grid item xs={12}>
                  <TextField
                  label="Certificate"
                  type="file"
                  name='certificate'
                  InputLabelProps={{
                    shrink: true,
                    accept: "image/*"
                  }}
                  onChange={(e) => handleFileChange(e, "certificate")}
                />
                  {certificateError && <p style={{ color: 'red' }}>{certificateError}</p>}
                </Grid>
                }

                {/* <Grid item xs={12}>
                  <TextField
                    label="Certificate"
                    name='certificate'
                    type="file"
                    InputLabelProps={{
                      shrink: true,
                      accept: "image/*"
                    }}
                    onChange={(e) => handleFileChange(e, "certificate")}
                  />
                  {certificateError && <p style={{ color: 'red' }}>{certificateError}</p>}
                </Grid> */}
              </Grid>

              <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12} sm={6}>
              <Button onClick={handleClose} sx={{ mr: 2 }}>
              Cancel
              </Button>
              </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleClickSave}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Modal>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={Previous}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default Experience;