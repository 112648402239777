const Conf = {
    api: {
        base_url: "https://propass-api.aiolos.cloud/",
    },
};
if (process.env.REACT_APP_ENV === "production") {
    Conf.api.base_url = "";
}

if (process.env.REACT_APP_ENV === "staging") {
    Conf.api.base_url = "";
}
export default Conf;