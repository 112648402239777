import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Stack,
  TextField,
  Autocomplete,
  InputAdornment,
  Popper,
  Card,
  ListItem,
  Modal,
  Button,
  IconButton, Badge, Divider
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';

import Iconify from '../../components/iconify';
// import WalletCard from '../../MetaMask';

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '100%',
  gap: '30px',
});

const StyledClosedButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  color: theme.palette.grey[500],
  transform: "scale(1.2)",
  zIndex: "100"
}));

const SearchSection = (props) => {
  // console.log('props', props);
  const [openModal, setOpenModal] = useState(false);

  // console.log(props)
  // useEffect(() => {
  //   setIsLoading(props.isLoading);
  // }, [setIsLoading]);
  // useEffect(() => {
  //   setIsConnected(props.isConnected);
  // }, [setIsConnected]);
  // useEffect(() => {
  //   setErrorMessage(props.errorMessage);
  // }, [setErrorMessage]);
  // useEffect(() => {
  //   setSuccessMessage(props.successMessage);
  // }, [setSuccessMessage]);

  const modalContentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "40vw",
    boxShadow: 24,
    backgroundColor: "#fff",
    p: 3,
    // pl: 4,
    // py: 2,
    // mt: 4
    pb: 4

  };


  return (
    <Card
      sx={{
        p: 5,
        textAlign: 'left',
      }}
    >
      <Typography variant="h4" sx={{ color: "#1DA7FF" }}>
        {props.user.displayName}
        {/* {props.auth.university ? 'Mumbai University' : 'Aiolos Cloud'} */}
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 1, mb: 3 }}>
        {/* {props.auth.university ? 'Student Degree Mint' : 'Mint Employee Experience'} */}
        {props.subtitle}
      </Typography>
      <Stack spacing={3}>
        {/* <TextField name="name" label="Enter Student Name" onChange={(e) => setData({ ...data, studentName: e.target.value })} /> */}
        <Autocomplete
          sx={{ width: '100%' }}
          autoHighlight
          popupIcon={null}
          PopperComponent={StyledPopper}
          options={props.names}
          getOptionLabel={(e) => e.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={props.handleSearch}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={props.auth.university ? 'Search Student...' : 'Search Employee...'}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Stack>
      {/* {props.selectedData && (
        <Grid sx={{ mt: 4 }}>
          {props.auth.university ? (
            <Stack spacing={1} sx={{ p: 1, pr: 0 }}>
              <ListItem sx={{p:0,mt:2}}>
                {props.selectedData.name && (
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Typography variant="h6" component="h6">
                        Name :{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>{props.selectedData.name}</Typography>
                    </Grid>
                  </Grid>
                )}
              </ListItem>
              <ListItem sx={{p:0,mt:2}}>
                {props.selectedData.degree && (
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Typography variant="h6" component="h6">
                        Degree :{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>{props.selectedData.degree}</Typography>
                    </Grid>
                  </Grid>
                )}
              </ListItem>
              <ListItem sx={{p:0,mt:2}}>
                {props.selectedData.gpa && (
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Typography variant="h6" component="h6">
                        GPA :{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>{props.selectedData.gpa}</Typography>
                    </Grid>
                  </Grid>
                )}
              </ListItem>
              <ListItem sx={{p:0,mt:2}}>
                {props.selectedData.graduationDate && (
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Typography variant="h6" component="h6">
                        Graduation Month :{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>{props.selectedData.graduationDate}</Typography>
                    </Grid>
                  </Grid>
                )}
              </ListItem>
            </Stack>
          ) : (
            <Stack spacing={1} sx={{ p: 1, pr: 0 }}>
              <ListItem sx={{p:0,mt:2}}>
                {props.selectedData.name && (
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Typography variant="h6" component="h6">
                        Name :{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>{props.selectedData.name}</Typography>
                    </Grid>
                  </Grid>
                )}
              </ListItem>
              <ListItem sx={{p:0,mt:2}}>
                {props.selectedData.position && (
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Typography variant="h6" component="h6">
                        Position :{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>{props.selectedData.position}</Typography>
                    </Grid>
                  </Grid>
                )}
              </ListItem>
              <ListItem sx={{p:0,mt:2}}>
                {props.selectedData.department && (
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Typography variant="h6" component="h6">
                        Department :{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>{props.selectedData.department}</Typography>
                    </Grid>
                  </Grid>
                )}
              </ListItem>
              <ListItem sx={{p:0,mt:2}}>
                {props.selectedData.joining_date && (
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Typography variant="h6" component="h6">
                        Joining Date :{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>{props.selectedData.joining_date}</Typography>
                    </Grid>
                  </Grid>
                )}
              </ListItem>
            </Stack>
          )}
        </Grid>
      )} */}
      {/* {props.selectedData &&
        <div className='buttonDisplay'>
          <Button sx={{ mt: 3 }} onClick={() => setOpenModal(true)}>Verify Data</Button>
        </div>
      } */}

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ pb: 5 }}
      >
        {props.selectedData ?
          <Card className='modalWrap' sx={modalContentStyle}>
            {/* <Typography className='modalHeader' variant="h1" component="h1">Verify Data</Typography> */}
            <Typography variant="h3" sx={{ mb: 1.5, pl: 3 }}>Candidate Details</Typography>
            <Divider sx={{ mb: 3 }} />
            <StyledClosedButton onClick={() => setOpenModal(false)}>
              <Badge color="secondary" >
                <Iconify icon="material-symbols:close" style={{ fontSize: "25px", opacity: 0.7 }} />
              </Badge>
            </StyledClosedButton>
            {props.auth.university ? (
              <Stack sx={{ pr: 0, width: "100%", display: "flex", gap: "10px", pl: 3 }}>
                {/* <ul className='para1'> */}
                <ListItem sx={{ p: 0 }}>
                  {props.selectedData.name && (
                    <Grid container >
                      <Grid item xs={5}>
                        <Typography variant="h6" component="h6">
                          Name :{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>{props.selectedData.name}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  {props.selectedData.degree && (
                    <Grid container>
                      <Grid item xs={5}>
                        <Typography variant="h6" component="h6">
                          Degree :{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>{props.selectedData.degree}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  {props.selectedData.gpa && (
                    <Grid container>
                      <Grid item xs={5}>
                        <Typography variant="h6" component="h6">
                          GPA :{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>{props.selectedData.gpa}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  {props.selectedData.graduationDate && (
                    <Grid container>
                      <Grid item xs={5}>
                        <Typography variant="h6" component="h6">
                          Graduation Month :{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>{props.selectedData.graduationDate}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
              </Stack>
            ) : (
              <Stack sx={{ pr: 0, width: "100%", display: "flex", gap: "10px", pl: 3 }}>
                {/* <ul className='para1'> */}
                <ListItem sx={{ p: 0 }}>
                  {props.selectedData.name && (
                    <Grid container>
                      <Grid item xs={5}>
                        <Typography variant="h6" component="h6">
                          Name :{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>{props.selectedData.name}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  {props.selectedData.position && (
                    <Grid container>
                      <Grid item xs={5}>
                        <Typography variant="h6" component="h6">
                          Position :{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>{props.selectedData.position}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  {props.selectedData.department && (
                    <Grid container>
                      <Grid item xs={5}>
                        <Typography variant="h6" component="h6">
                          Department :{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>{props.selectedData.department}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  {props.selectedData.joining_date && (
                    <Grid container>
                      <Grid item xs={5}>
                        <Typography variant="h6" component="h6">
                          Joining Date :{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>{props.selectedData.joining_date}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
              </Stack>
            )}
            <LoadingButton
              disabled={props.isLoading}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              // className='connectBtn'
              onClick={() => props.handleSubmit(props.type, props.task)}
              sx={{
                backgroundColor: '#5051F9', width: "fit-content", px: 7, ml: 3, mt: 5
              }}
              xs={12}
            >
              {/* eslint-disable-next-line  */}
              {/* {console.log("connected " + props.isConnected)} */}
              {props.isConnected ? (
                <>{props.isLoading ? 'Please wait' : props.auth.university ? 'Issue Degree' : 'Issue Experience Certificate'}</>
              ) : (
                'Connect Wallet'
              )}



            </LoadingButton>
            {/* {props.errorMessage ? (
              <Typography variant="body1" color="red" sx={{ mt: 2 }}>
                Error: {props.errorMessage}
              </Typography>
            ) : null}
            <Stack spacing={3} />
            {props.successMessage ? (
              <Typography variant="body1" color="green" sx={{ mt: 2 }}>
                Success: {props.successMessage}
              </Typography>
            ) : null} */}
            {props.errorMessage ? (
              <Typography variant="body1" color="red" sx={{ mt: 2, pl: 3 }}>
                Error: {props.errorMessage}
              </Typography>
            ) : null}
            <Stack spacing={3} />
            {props.successMessage ? (
              <Typography variant="body1" color="green" sx={{ mt: 3, pl: 3 }}>
                Success: {props.successMessage}
              </Typography>
            ) : null}

          </Card> : <div>null</div>
        }




      </Modal >


      {/* <Grid className='buttonDisplay' sx={{ justifyContent: "space-between", alignItems: "center", mt: 3 }}> */}
      < Grid sx={{ justifyContent: "space-between", alignItems: "center", mt: 3, display: "flex" }}>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          // className='connectBtn'
          onClick={() => setOpenModal(true)}
          sx={{
            backgroundColor: '#5051F9', width: "fit-content", px: 7,
            textTransform: "none"
          }}
          xs={12}
        >
          Search user

        </LoadingButton>
        {/* <Typography xs={12} className='anyQueryPara' variant='subtitle1'> */}
        <Typography xs={12} sx={{ mt: 2 }} variant='subtitle1'>
          <span style={{ opacity: "0.6" }}>Any Query?</span> <span style={{ color: "#5051F9" }}>Contact Us</span>
        </Typography>
      </Grid >



      {/* <WalletCard /> */}

      {/* </Container> */}
    </Card >

  );
};

export default SearchSection;
