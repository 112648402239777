// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

// const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const icon = (name) => <SvgColor src={`/assets/icons/navbar/new/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const universityConfig = [
  {
    title: 'Issue Degree',
    path: '/university/home',
    icon: icon('degree'),
  },
  {
    title: 'Semester Results',
    path: '/university/mint-result',
    icon: icon('result'),
  },
  {
    title: 'Mint Certificate',
    path: '/university/mint-certificate',
    icon: icon('certificate'),
  },
  {
    title: 'Register Mod',
    path: '/university/register-mod',
    icon: icon('result'),
  },
];


const employerConfig = [
  {
    title: 'Issue Experience',
    path: '/employer/home',
    icon: icon('degree'),
  },
  {
    title: 'Fetch Data',
    path: '/employer/fetch-data',
    icon: icon('certificate'),
  },
  {
    title: 'Mint Certificate',
    path: '/employer/mint-certificate',
    icon: icon('result'),
  }, {
    title: 'Register Mod',
    path: '/employer/register-mod',
    icon: icon('result'),
  },
];

export {
  universityConfig,
  employerConfig
}