import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import Cookies from 'universal-cookie';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover, Grid } from '@mui/material';
// mocks_
import { universityAccount, employerAccount } from '../../../_mock/account';
import Iconify from '../../../components/iconify';
import userIcon from '../../../assets/icons/user-icon.svg';
// ----------------------------------------------------------------------
// const cookies = new Cookies();
const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover({ setAuth, auth }) {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
    localStorage.removeItem("token");

  };
  const handdleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth');
    setAuth({ loggedIn: false, university: false, employer: false, logo: "" });
    navigate('/', { replace: true });
  };
  const account = auth.university ? universityAccount : employerAccount;

  return (
    <>
      <Grid alignItems={'center'} display={'flex'}>
        {/* <Typography color={"text.primary"} variant="subtitle1">
          {account.displayName}
        </Typography > */}
        <IconButton
          onClick={handleOpen}
          sx={{
            p: 0.7,
            bgcolor: "#E7E7FF",
            boxShadow: (theme) => theme.customShadows.z16,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.3),
              },
            }),
          }}
        >
          <Avatar src={userIcon} alt="photoURL" />

        </IconButton>
        <IconButton
          onClick={handleOpen}
          sx={{ p: "5px", }}
        >
          <Iconify icon="material-symbols:keyboard-arrow-down" style={{ fontSize: "25px" }} />
        </IconButton>
      </Grid>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {auth.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {auth.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            handleClose();
            handdleLogout();
          }}
          sx={{ m: 1 }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
