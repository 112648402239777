import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Drawer, Typography, Grid } from '@mui/material';
import NavSection from '../../../components/nav-section';
import NotificationsPopover from '../header/NotificationsPopover';

import { universityAccount, employerAccount } from '../../../_mock/account';
import { universityConfig, employerConfig } from './config';
// utils
import { bgBlur } from '../../../utils/cssStyles';
import AccountPopover from '../header/AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import NotificationsPopover from './NotificationsPopover';


import helloIcon from "../../../assets/svg/hello_hand.svg"
import Logo from '../../../components/logo';
import useResponsive from '../../../hooks/useResponsive';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  backgroundColor: "#FFFFFF",
  // padding: "10px 0 "
  // [theme.breakpoints.up('lg')]: {
  //   width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  // },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  display: "flex",  
  // [theme.breakpoints.up('lg')]: {
  //   minHeight: HEADER_DESKTOP,
  //   padding: theme.spacing(0, 5),
  // },
}));

const Items = styled(Box)(({ theme }) => ({
  flex: "1",
}));
// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
  setLoggedIn: PropTypes.func
};

export default function Header({ onOpenNav, auth, setAuth, openNav, onCloseNav }) {
  const account = auth.university ? universityAccount : employerAccount;
  const isDesktop = useResponsive('up', 'lg');

  return (
  <>
    {isDesktop ? 
    <StyledRoot>
      <StyledToolbar className='headerWrap'>
        <Items sx={{ px: 2.5, py: 3, display: 'inline-flex', justifyContent: "center", flex: 0.85 }}>
          <Logo />
        </Items>
        <Items sx={{ flex: 0.8 }}>
          <Typography color={"GrayText"}  >
            Hi, Welcome Back
          </Typography>
        </Items>
        <Items sx={{ flex: 2 }}>
          <NavSection data={auth.university ? universityConfig : employerConfig} auth={auth} isDesktop={isDesktop} />

        </Items>
        <Items sx={{ display: "flex", justifyContent: "end" }}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              gap: "30px"
            }}
          >
            <NotificationsPopover />
            <AccountPopover auth={auth} setAuth={setAuth} />
          </Stack>
        </Items>
        {/* <Searchbar /> */}

      </StyledToolbar>
    </StyledRoot> : <StyledRoot>
      {/* mobile */}
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Items sx={{ display: "flex", justifyContent: "end" }}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              gap: "30px"
            }}
          >
            <NotificationsPopover />
            <AccountPopover auth={auth} setAuth={setAuth} />
          </Stack>
        </Items>
        <Drawer
          
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          <Items sx={{ flex: 0, pt: 2, justifyContent: "center" }}  >
            <Logo style={{ justifyContent: "center", marginTop: "15px" }} />
          </Items>
          <NavSection  sx={{ flex: 0, mt: 4 }} data={auth.university ? universityConfig : employerConfig} auth={auth} isDesktop={isDesktop} />

        </Drawer>
      </StyledToolbar>
    </StyledRoot>
    }
  </>
  );
}
