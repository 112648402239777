// import PropTypes from 'prop-types';
// import { NavLink as RouterLink } from 'react-router-dom';
// // @mui
// import { Box, List, ListItemText } from '@mui/material';
// //
// import { StyledNavItem, StyledNavItemIcon } from './styles';

// // ----------------------------------------------------------------------

// NavSection.propTypes = {
//   data: PropTypes.array,
// };

// export default function NavSection({ data = [], auth, ...other }) {
//   return (
//     <Box {...other} sx={{
//       mt: 4,
//     }}>
//       <List disablePadding sx={{ p: 1}}>
//         {data.map((item) => {
//           if (item.path.includes("/register-mod") && (auth.role !== "admin" && auth.role !== "superadmin")) return null;
//           return <NavItem key={item.title} item={item} />
//         })}
//       </List>
//     </Box>
//   );
// }

// // ----------------------------------------------------------------------

// NavItem.propTypes = {
//   item: PropTypes.object,
// };

// function NavItem({ item }) {
//   const { title, path, icon, info } = item;

//   return (
//     <StyledNavItem
//       component={RouterLink}
//       to={path}
//       sx={{
//         '&.active': {
//           // color: 'text.primary',
//           color: '#339DFF',
//           bgcolor: 'action.selected',
//           fontWeight: 'fontWeightBold',

//         },
//       }}
//     >
//       <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

//       <ListItemText disableTypography primary={title} style={{ letterSpacing: "0.5px" }} />

//       {info && info}
//     </StyledNavItem>
//   );
// }
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], auth, isDesktop, ...other }) {
  return (
    <Box {...other} >
      <List className='mobileNavList' disablePadding sx={{ p: 1, display: "flex", flexDirection: isDesktop ? "row" : "column" }}>
        {data.map((item) => {
          if (item.path.includes("/register-mod") && (auth.role !== "admin" && auth.role !== "superadmin")) return null;
          return <NavItem key={item.title} item={item} />
        })}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        fontSize: "15px",
        textAlign: "center",
        '&.active': {
          // color: 'text.primary',
          color: '#339DFF',
          // bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      {/* <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon> */}

      <ListItemText disableTypography primary={title} style={{ letterSpacing: "0.5px" }} />

      {info && info}
    </StyledNavItem>
  );
}
