import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import { Grid, Stack } from '@mui/material';

// sections
import { AppWidgetSummary } from '../../../sections/@dashboard/app';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Metrics(props) {
    return (
        <Stack container spacing={3} sx={{ justifyContent: "space-between" }}>
            <Grid item minWidth={"100%"} xs={12} sm={6} md={3} >
                <AppWidgetSummary title="Total User" total={500000} icon={'mdi:account-student'} />
            </Grid>

            <Grid item minWidth={"100%"} xs={12} sm={6} md={3}>
                <AppWidgetSummary
                    title="Completed Mint"
                    total={300000}
                    color="info"
                    icon={'mdi:clipboard-check-outline'}
                />
            </Grid>

            <Grid item minWidth={"100%"} xs={12} sm={6} md={3}>
                <AppWidgetSummary
                    title="Pending Mint"
                    total={140000}
                    color="warning"
                    icon={'material-symbols:pending-actions'}
                />
            </Grid>

            {/* <Grid item minWidth={"100%"} xs={12} sm={6} md={3}>
                <AppWidgetSummary
                    title="Mint In Process"
                    total={10000}
                    color="error"
                    icon={'material-symbols:nest-clock-farsight-analog-outline-rounded'}
                />
            </Grid> */}
        </Stack>
    );
}
