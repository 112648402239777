import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
// components
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const [productData, setProductData] = useState({});
  // let keys = []
  // let values = []
  useEffect(() => {
    const data = {}
    const type = product.type;
    const task = product.task;
    if (type === 'student') {
      if (task === "degree") {

        data.Name = product.badge.name;
        data.Title = "Degree";
        // data["Majors in"] = "Computers";
        // data["Joining Date"] = "7th June 2019";
        // data["Completion Date"] = "31st March 2023";
        data["Majors in"] = product.badge.major;
        data["Joining Date"] = product.badge.dateOfJoining;
        data["Completion Date"] = product.badge.dateOfCompletion;
        data["Issued Date"] = product.issuedDate;

      } else if (task === "result") {

        data.Name = product.badge.name;
        data.Title = `Sem ${product.badge.semester} Result`;
        data["Joining Date"] = product.badge.dateOfJoining;
        data["Completion Date"] = product.badge.dateOfCompletion;
        data["Issued Date"] = product.issuedDate;

      } else if (task === "certificate") {
        data.Name = product.badge.name;
        data.Title = `Certificate`;
        data.For = product.badge.forWhat;

      }

    } else if (type === 'employee') {
      if (task === "experience") {

        data.Name = product.badge.name;
        data.Title = "Experience badge";
        // data.Department = "IT";
        // data.Position = "Software Engineer";
        // data["Start Date"] = "2nd August 2021";
        // data["End Date"] = product.issuedDate;
        data.Department = product.badge.department;
        data.Position = product.badge.position;
        data["Start Date"] = product.badge.dateOfJoining;
        data["End Date"] = product.issuedDate;
        data["Issued Date"] = product.issuedDate;


      } else if (task === "certificate") {

        data.Name = product.badge.name;
        data.Title = `Certificate`;
        data.For = product.badge.forWhat;
        data.Department = product.badge.department;
        data.Position = product.badge.position;
      }
    }
    setProductData(data);
  }, [])


  return (
    <Card sx={{ height: "100%" }}>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <StyledProductImg alt={product.name} src={`https://ipfs.io/ipfs/${product.image.substring(7)}`} />
      </Box>

      <Stack spacing={2} sx={{ p: 3, backgroundColor: "transparent" }}>
        <Link color="inherit" underline="none" sx={{
          '&:hover': {
            textDecoration: "none",
            color: "inherit"
          },
        }}>
          <Typography variant="h6" noWrap textAlign={"center"}>
            {product.title}
          </Typography>

          {Object.keys(productData).map((keyName, i) => (
            <Grid container display={"flex"} sx={{ mt: 1 }} key={i}>
              <Grid item xs={6}>
                <Typography variant="subtitle2" noWrap >
                  {keyName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" noWrap >
                  {productData[keyName]}
                </Typography>
              </Grid>
            </Grid>
          ))}
          {/* <Grid container display={"flex"} sx={{ mt: 1 }} >
            <Grid item xs={6}>
              <Typography variant="subtitle2" noWrap >
                Name
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" noWrap >
                {product.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container display={"flex"} sx={{ mt: 1 }} >
            <Grid item xs={6}>
              <Typography variant="subtitle2" noWrap >
                {product.title === "Degree" ? "Degree Name" : "Designation"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" noWrap >
                {product.title === "Degree" ? product.degreeName : product.designation}
              </Typography>
            </Grid>
          </Grid>
          <Grid container display={"flex"} sx={{ mt: 0.5 }}>
            <Grid item xs={6}>
              <Typography variant="subtitle2" noWrap >
                Issued Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" noWrap >
                {product.issuedDate}
              </Typography>
            </Grid>
          </Grid>
          {product.dateOfJoining && <Grid container display={"flex"} sx={{ mt: 0.5 }}>
            <Grid item xs={6}>
              <Typography variant="subtitle2" noWrap >
                Start Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" noWrap >
                {product.dateOfJoining}
              </Typography>
            </Grid>
          </Grid>}
          {product.dateOfCompletion && <Grid container display={"flex"} sx={{ mt: 0.5 }}>
            <Grid item xs={6}>
              <Typography variant="subtitle2" noWrap >
                End Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" noWrap >
                {product.dateOfCompletion}
              </Typography>
            </Grid>
          </Grid>} */}
        </Link>

      </Stack>

    </Card >
  );
}
