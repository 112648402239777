import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
    Grid,
    Typography,
    Stack,
    TextField,
    Autocomplete,
    InputAdornment,
    Popper,
    Card,
    ListItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import ProductList from '../../sections/@dashboard/products/ProductList';

// import SearchSection from './SearchSection'
import { getBalance, getTokenOfOwnerByIndex, getTokenUri } from '../../interact';

import { employerAccount } from '../../_mock/account';
import fetchNftHero from '../../assets/svg/fetch-nft.svg';

const FetchData = ({ ...props }) => {
    const [walletAddress, setWalltetAddress] = useState(null);
    const [nfts, setNfts] = useState([]);
    const [showNullNft, setShowNullNft] = useState(false);
    const [balance, setBalance] = useState(-1);
    const [loading, setIsLoading] = useState(false);
    const handleFetchNfts = async () => {
        setIsLoading(true);
        setNfts([]);
        const balance = await getBalance(walletAddress);
        setBalance(balance);
        if (balance === '0') {setShowNullNft(true)} else {setShowNullNft(false)};
        console.log({ TotalBalance: balance });
        const tokenURIs = [];
        const tempNfts = [];
        /* eslint-disable*/
        if (balance > 0) {
            for (var i = 0; i < balance; i++) {
                console.log('In for', i);
                /* eslint-disable*/
                const globalIndex = await getTokenOfOwnerByIndex(walletAddress, i);
                if (globalIndex) {
                    const tokenUri = await getTokenUri(globalIndex);
                    console.log({ globalIndex, tokenUri });
                    if (tokenUri) {
                        if (tokenUri.includes('ipfs')) {
                            tokenURIs.push(tokenUri.substring(7));
                        } else {
                            tokenURIs.push(tokenUri);

                        }
                    }
                }
            }

            console.log({ tokenURIs });

            for (var i = 0; i < tokenURIs.length; i++) {
                // let metaData = await axios.get(`https://ipfs.io/ipfs/${tokenURIs[i].substring(7)}`);
                let metaData = await axios.get(`https://ipfs.io/ipfs/${tokenURIs[i]}`);
                metaData = metaData.data;
                console.log({ metaData })
                var date = new Date(1666632563517);
                date = date.toLocaleDateString('en-US')
                console.log({ metaData })


                // const curr = {
                // id: i,
                // cover: `https://ipfs.io/ipfs/${metaData.image.substring(7)}`,
                // Name: metaData.badge.name,
                // Title: metaData.badge.title,

                // title: metaData.name,
                // name: metaData.badge.name,
                // description: metaData.badge.description,
                // issuedDate: date,
                // dateOfJoining: metaData.dateOfJoining,
                // dateOfCompletion: metaData.dateOfCompletion,
                // designation: metaData.badge.designation,
                // degreeName: metaData.badge.degreeName,
                // };
                tempNfts.push(metaData);
                setNfts(tempNfts);
                console.log('Token URI: ', tempNfts);
            }
        }
        setIsLoading(false);
    };

    return (
        <>
            <Grid
                container
                sx={{
                    p: 2
                    // display: "flex"
                }}>
                <Card sx={{ width: "100%", py: 5 }}>
                    <Row className='pt-2 pb-2'>
                        <Col sm={6} xs={{ order: 'last' }} className="text-center text-sm-left mt-5 mt-sm-0">
                            <Typography variant="h3">{employerAccount.displayName}</Typography>
                            <Typography variant="subtitle1" sx={{ mt: 1, mb: 5 }}>
                                Fetch Employee Credentials
                            </Typography>
                            <Stack spacing={3} className='col-12 col-sm-8 p-0'>
                                <TextField
                                    name="name"
                                    label="Enter Employee wallet address"

                                    onChange={(e) => setWalltetAddress(e.target.value)}
                                    sx={{
                                        background: '#F6F8FE',
                                        border: 'none',
                                    }}
                                />
                            </Stack>

                            <LoadingButton
                                disabled={loading}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                className="connectBtn col-12 col-sm-8"
                                onClick={props.account ? handleFetchNfts : props.handleMetamaskLogin}
                                sx={{ mt: 5, backgroundColor: '#339DFF' }}

                            >
                                {loading ? 'Please wait' : 'Check Credentials'}
                            </LoadingButton>
                            {/* </Container> */}
                        </Col>
                        <Col sm={6} xs={{ order: 'first' }} className="text-center">
                            <img src={fetchNftHero} alt={'fetch nft hero'} className="img-fluid d-inline" />
                        </Col>
                    </Row>
                </Card>
            </Grid>
            {console.log({ nftsFromHTML: nfts, showNullNft })}
            {
                (nfts.length > 0 || showNullNft) && (
                    <Card
                        container
                        sx={{
                            p: 5,
                            m: 2,
                            mt: 4,
                            textAlign: 'left',
                            // display: "flex"
                        }}
                    >
                        <Grid container spacing={3} sx={{ mb: 4 }}>
                            <Grid item xs={24} sm={24} md={24}>
                                <Typography variant="h6" display={'flex'}>
                                    Credentials For :
                                    <Typography variant="subtitle2" display="flex" alignItems={'center'} sx={{ ml: 2 }}>
                                        {walletAddress}
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>

                        {showNullNft ? (
                            <Typography variant="subtitle2" sx={{ mt: 4 }}>
                                No Credentials Found For this wallet address
                            </Typography>
                        ) : (
                            <ProductList sx={{ mt: 2 }} products={nfts} />
                        )}
                    </Card>
                )
            }
        </>
    );
};

export default FetchData;
