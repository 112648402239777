import React, { useState } from 'react';
import axios from 'axios';
import dayjs from "dayjs";
import { TextField, Button, Card, Grid } from '@mui/material';
import { Container } from '@mui/system';
import { styled } from '@mui/material/styles';
import BasicDetails from './BasicDetails';
import Education from './Education';
import Experience from './Experience';
import Success from './Success';
import config from '../../../config';


const StyledContent = styled('div')(({ theme }) => ({
  margin: "auto",
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(5, 3),
  width: "40vw"
}));

const RegisterUser = () => {
  const [step, setStep] = useState(1);

  const [basicDetails, setBasicDetails] = useState({
    name: '',
    phone: '',
    email: '',
  });

  const [education, setEducation] = useState([]);

  const [workExperience, setWorkExperience] = useState([]);

  const handleBasicDetailsChange = e => {
    setBasicDetails({ ...basicDetails, [e.target.name]: e.target.value });
  };

  const handleEducationChange = (e, index) => {
    const updatedEducation = [...education];
    updatedEducation[index][e.target.name] = e.target.value;
    setEducation(updatedEducation);
  };


  // const handleWorkExperienceChange = (e, index) => {
  //   const updatedWorkExperience = [...workExperience];
  //   updatedWorkExperience[index][e.target.name] = e.target.value;
  //   setWorkExperience(updatedWorkExperience);
  // };

  const addExperience = (e, data) => {
    e.preventDefault()
    data.startDate = dayjs(data.startDate).format("MMM YYYY");
    data.endDate = dayjs(data.endDate).format("MMM YYYY");
    setWorkExperience([...workExperience, data]);
  }

  const addEducation = (e, data) => {
    e.preventDefault()
    data.startDate = dayjs(data.startDate).format("MMM YYYY");
    data.endDate = dayjs(data.endDate).format("MMM YYYY");
    setEducation([...education, data]);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ basicDetails, education, workExperience });
    axios.post(`${config.api.base_url}register-user`, { basicDetails, education, workExperience }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },).then((resp) => {

      if (resp.data.Success) {
        alert("registered successfully")
      } else {
        alert("error while registering")
      }
    }).catch((err) => console.log(err))
  }

  const prevStep = () => {
    setStep(step - 1);
  }

  const nextStep = () => {
    setStep(step + 1);
  }

  switch (step) {
    case 1:
      return (
        <Container sx={{ display: "flex !important", justifyContent: "center", marginTop: "20vh", minHeight: "100vh" }} >
          <Card>
            <StyledContent>
              <BasicDetails
                nextStep={nextStep}
                handleChange={handleBasicDetailsChange}
                values={basicDetails}
              />
            </StyledContent>
          </Card>
        </Container>
      );
    case 2:
      return (
        <Container sx={{ display: "flex !important", justifyContent: "center", marginTop: "20vh", minHeight: "100vh" }}>
          <Card>
            <StyledContent>

              <Education
                prevStep={prevStep}
                nextStep={nextStep}
                handleChange={handleEducationChange}
                values={education}
                handleSave={addEducation}
              />
            </StyledContent>
          </Card>
        </Container>
      );
    case 3:
      return (
        <Container sx={{ display: "flex !important", justifyContent: "center", marginTop: "20vh", minHeight: "100vh" }}>
          <Card>
            <StyledContent>
              <Experience
                prevStep={prevStep}
                nextStep={nextStep}
                handleSave={addExperience}
                values={workExperience}
                handleSubmit={handleSubmit}
              />
            </StyledContent >
          </Card >
        </Container >
      );
    case 4:
      return (
        <Success
          value={{ basicDetails, education, workExperience }}
        />
      );
    default:
      return <></>;
  }
};

export default RegisterUser;