import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Link, Container, Typography, Divider, Stack, Button, Grid, TextField, IconButton, InputAdornment, Autocomplete, Popper, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from "axios"
import { styled } from '@mui/material/styles';
import Iconify from '../../components/iconify';
import config from "../../config"

// hooks
import useResponsive from '../../hooks/useResponsive';

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
    width: '100%',
    gap: '30px',
});

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(5, 3),
    
}));

const RegisterMod = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState({ email: "", password: "", name: "", entity: "" });
    const [entities, setEntites] = useState([])

    const reset = () => {

        setData({ email: "", password: "", name: "", entity: "" });
    }



    useEffect(() => {
        // const url = auth.university ? `${config.api.base_url}student-names` : `${config.api.base_url}employee-names`;
        if (props.auth.role === "superadmin") {
            const url = `${config.api.base_url}get-entities`;
            axios.get(url, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
                .then((resp) => {
                    if (resp.data.success) {
                        resp = resp.data.data;

                        // console.log({ resp })
                        setEntites(resp)
                    } else {
                        console.log('Error while fetching Names');
                    }
                })
                .catch((e) => {
                    console.log('Error while fetching Names');
                    console.log(e);
                });
        } else {
            setData({ ...data, entity: props.auth.id });

        }
    }, [setEntites]);


    return (
        <>

            <Container maxWidth="sm">
                <Card>
                    <StyledContent>
                        <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
                            Register a Moderator
                        </Typography>

                        <Stack direction="row" spacing={1}>
                            <Button fullWidth size="large" color="inherit" variant="outlined">
                                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
                            </Button>

                            <Button fullWidth size="large" color="inherit" variant="outlined">
                                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
                            </Button>

                            <Button fullWidth size="large" color="inherit" variant="outlined">
                                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
                            </Button>
                        </Stack>

                        <Divider sx={{ my: 3 }}>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                OR
                            </Typography>
                        </Divider>

                        <Stack spacing={3}>
                            <TextField name="name" label="Name" value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} />

                            {props.auth.role === "superadmin" && <Stack spacing={3}>
                                <Autocomplete
                                    sx={{ width: '100%' }}
                                    autoHighlight
                                    popupIcon={null}
                                    PopperComponent={StyledPopper}
                                    options={entities}
                                    // value={data.entity}
                                    inputValue={data.entity}
                                    getOptionLabel={(e) => e.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(e, option) => { setData({ ...data, entity: option._id }) }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={"Search Entities"}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Stack>}

                            <TextField name="email" label="Email address" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />

                            <TextField
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                value={data.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => setData({ ...data, password: e.target.value })}
                            />
                        </Stack>

                        <LoadingButton
                            sx={{ mt: 3 }}
                            fullWidth size="large" type="submit" variant="contained" onClick={() => { props.handler(data, false, reset) }}>
                            Register
                        </LoadingButton>
                    </StyledContent>
                </Card>
            </Container>

        </>

    )
}

export default RegisterMod