import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import { Grid, Container } from '@mui/material';

import config from '../../config';

// sections
// import { AppWidgetSummary } from '../../sections/@dashboard/app';
import SearchSection from './SearchSection';
import CustomerCare from '../../components/CustomerCare';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function MintCertificates(props) {
    console.log('mint nft props', props);
    const [isLoading, setIsLoading] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [errorMessage, setErrorMessage] = useState(props.errorMessage);
    const [successMessage, setSuccessMessage] = useState(props.successMessage);
    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);
    useEffect(() => {
        setIsConnected(props.isConnected);
    }, [props.isConnected]);
    useEffect(() => {
        setErrorMessage(props.errorMessage);
    }, [props.errorMessage]);
    useEffect(() => {
        setSuccessMessage(props.successMessage);
    }, [props.successMessage]);
    return (
        <>
            <Helmet>
                {props.auth.university ? <title> Issue Degree | Home </title> : <title> Issue Experience Certificate | Home </title>}
            </Helmet>

            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid container spacing={3} sx={{ ml: 0, mt: 1 }}>
                        <Grid item xs={12} md={6}>
                            <SearchSection
                                {...props}
                                isLoading={isLoading}
                                errorMessage={errorMessage}
                                isConnected={isConnected}
                                successMessage={successMessage}
                                type={"student"}
                                task={"certificate"}
                                mintButtonText={"Issue Certificate"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomerCare />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
