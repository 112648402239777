import React from 'react'

const Success = (values) => {
  console.log(values);
  return (
    <div>
     <></>
    </div>
  )
}

export default Success