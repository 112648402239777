const { createAlchemyWeb3 } = require('@alch/alchemy-web3');

const contractABI = require('./contract-abi-sepolia.json');

const contractAddress = '0x2aD1335Cd247AEB04b92A7241eC1C7Fd7DbDBDD0';
// Goerli Contract address
// const contractAddress = '0xFEB74eDA48B2c56A62Bc92595A20f95463704461';
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const web3 = createAlchemyWeb3(alchemyKey);
export const contract = new web3.eth.Contract(contractABI, contractAddress);

export const getBalance = async (_account) => {
  const message = await contract.methods.balanceOf(_account).call();
  return message;
};

export const getTokenOfOwnerByIndex = async (_account, _index) => {
  const message = await contract.methods.tokenOfOwnerByIndex(_account, _index).call();
  return message;
};

export const getTokenUri = async (_index) => {
  const message = await contract.methods.tokenURI(_index).call();
  return message;
};

export const mintNFTCreds = async (address, tokenUri, currentWalletAddress) => {
  //  input error handling
  if (!window.ethereum || address === null) {
    return {
      status: '💡 Connect your Metamask wallet to update the message on the blockchain.',
    };
  }

  //  set up transaction parameters
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: currentWalletAddress, // must match user's active address.
    data: contract.methods.safeMint(address, tokenUri).encodeABI(),
  };

  //  sign the transaction
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });
    return {
      status: 'success',
      message: (
        <span>
          ✅{' '}
          {/* <a target="_blank" rel="noreferrer" href={`https://goerli.etherscan.io/tx/${txHash}`}> */}
          <a target="_blank" rel="noreferrer" href={`https://sepolia.etherscan.io/tx/${txHash}`}>
            View the status of your transaction on Etherscan!
          </a>
          <br />
          ℹ️ Once the transaction is verified by the network, it will be reflected here.
          <br />
        </span>
      ),
    };
  } catch (error) {
    return {
      status: 'error',
      message: `😥 ${error.message}`,
    };
  }
};
