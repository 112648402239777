// // @mui
// import PropTypes from 'prop-types';
// import { alpha, styled } from '@mui/material/styles';
// import { Card, Typography } from '@mui/material';
// // utils
// import { fShortenNumber } from '../../../utils/formatNumber';
// // components
// import Iconify from '../../../components/iconify';

// // ----------------------------------------------------------------------

// const StyledIcon = styled('div')(({ theme }) => ({
//   margin: 'auto',
//   display: 'flex',
//   borderRadius: '50%',
//   alignItems: 'center',
//   width: theme.spacing(8),
//   height: theme.spacing(8),
//   // width: theme.spacing(6),
//   // height: theme.spacing(6),
//   justifyContent: 'center',
//   marginBottom: theme.spacing(3),
// }));

// // ----------------------------------------------------------------------

// AppWidgetSummary.propTypes = {
//   color: PropTypes.string,
//   icon: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   total: PropTypes.number.isRequired,
//   sx: PropTypes.object,
// };

// export default function AppWidgetSummary({ title, total, icon, color = 'primary', sx, ...other }) {
//   return (
//     <Card
//       sx={{
//         // py: 5,
//         py: 4,
//         // boxShadow: 0,
//         textAlign: 'center',
//         color: (theme) => theme.palette[color].darker,
//         // bgcolor: (theme) => theme.palette[color].lighter,
//         ...sx,
//       }}
//       {...other}
//     >
//       <StyledIcon
//         sx={{
//           color: (theme) => theme.palette[color].dark,
//           // backgroundImage: (theme) =>
//           //   `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
//           //     theme.palette[color].dark,
//           //     0.24
//           //   )} 100%)`,
//         }}
//       >
//         <Iconify icon={icon} width={35} height={35} />
//       </StyledIcon>

//       {/* <Typography variant="h3">{fShortenNumber(total)}</Typography> */}
//       <Typography variant="h3">{total}</Typography>

//       <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
//         {title}
//       </Typography>
//     </Card>
//   );
// }
// @mui
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, Grid, Typography } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  // width: theme.spacing(8),
  // height: theme.spacing(8),
  // width: theme.spacing(6),
  // height: theme.spacing(6),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, total, icon, color = 'primary', sx, ...other }) {
  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false
      }
    },
    grid: {
      show: false
    },
    series: [
      {
        name: 'Sales',
        data: [30, 80, 35, 100, 60, 120]
      }
    ],
    xaxis: {
      // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']
      show: false,
      labels: {
        show: false
      }
    },
    yaxis: {
      show: false
    },
    zoom: {
      enabled: false
    }, stroke: {
      curve: 'smooth',
    }
  };
  return (
    <Card
      sx={{
        // py: 5,
        py: 3,
        px: 3,
        // boxShadow: 0,
        // textAlign: 'center',
        color: (theme) => theme.palette[color].dark,
        // bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <Grid sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <StyledIcon
          sx={{
            color: (theme) => theme.palette[color].dark,
            margin: 0,
            bgcolor: "#EEEBFF",
            p: 1.5,

          }}
        >
          <Iconify icon={icon} width={30} height={30} />

        </StyledIcon>
        <Typography variant='subtitle1'>{title}</Typography>
      </Grid>

      <Grid sx={{ display: "flex", gap: "20px" }}>
        <Grid flex={1}>
          {/* <Typography variant="h3">{fShortenNumber(total)}</Typography> */}
          <ReactApexChart options={chartOptions} series={chartOptions.series} type="line" />
        </Grid>
        <Grid sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", color: "#000", alignItems: "center", textAlign: "left", }}>

          <Typography className='heading4' sx={{ fontWeight: "bold", fontSize: "2.5rem" }} >{total}</Typography>

          <Typography sx={{ opacity: 0.6, letterSpacing: "1px", fontSize: "1rem" }}>
            {/* {title} */}
            from last week
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
