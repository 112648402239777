import { useState } from 'react';

import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Stack, IconButton, InputAdornment, TextField, Container, Typography, Divider, Button, Grid, FormControl, Select, InputLabel, MenuItem, Input } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks

import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
// sections
import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage(props) {
    const mdUp = useResponsive('up', 'md');
    const [isloggin, setIsLoggin] = useState(true);
    const handleIsLogginChange = () => {
        setIsLoggin(!isloggin);
    }
    const [data, setData] = useState({ name: "", type: "", logo: "", email: "", password: "" });
    const [selectedLogo, setSelectedLogo] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const reset = () => {
        setData({ name: "", type: "", logo: "", email: "", password: "" });
    }

    return (
        <>
            <Helmet>
                {isloggin ? <title> Login | Professional Passport </title> : <title> Register | Professional Passport </title>}
            </Helmet>
            <StyledRoot>
                {isloggin ? <Container maxWidth="sm">
                    <StyledContent>
                        <Typography variant="h4" gutterBottom>
                            Entity Login
                        </Typography>

                        <Typography variant="body2" sx={{ mb: 5 }}>
                            Don’t have an account? {''}
                            <Link onClick={handleIsLogginChange} variant="subtitle2" sx={{ cursor: "pointer" }}>Create one</Link>
                        </Typography>

                        <Stack direction="row" spacing={2}>
                            <Button fullWidth size="large" color="inherit" variant="outlined">
                                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
                            </Button>

                            <Button fullWidth size="large" color="inherit" variant="outlined">
                                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
                            </Button>

                            <Button fullWidth size="large" color="inherit" variant="outlined">
                                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
                            </Button>
                        </Stack>

                        <Divider sx={{ my: 3 }}>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                OR
                            </Typography>
                        </Divider>

                        <Stack spacing={3}>
                            <TextField name="email" label="Email address" onChange={(e) => setData({ ...data, email: e.target.value })} />

                            <TextField
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => setData({ ...data, password: e.target.value })}
                            />
                        </Stack>

                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                            {/* <Checkbox name="remember" label="Remember me" /> */}
                            <Link variant="subtitle2" underline="hover">
                                Forgot password?
                            </Link>
                        </Stack>

                        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={() => props.handler(data, isloggin, handleIsLogginChange, reset)}>
                            Login
                        </LoadingButton>

                    </StyledContent>
                </Container> : <Container maxWidth="sm">
                    <StyledContent>
                        <Typography variant="h4" gutterBottom>
                            Entity Register
                        </Typography>

                        <Typography variant="body2" sx={{ mb: 5 }}>
                            Already have an account? {''}
                            <Link sx={{ cursor: "pointer" }} onClick={handleIsLogginChange} variant="subtitle2">Login Now</Link>
                        </Typography>

                        <Stack direction="row" spacing={2}>
                            <Button fullWidth size="large" color="inherit" variant="outlined">
                                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
                            </Button>

                            <Button fullWidth size="large" color="inherit" variant="outlined">
                                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
                            </Button>

                            <Button fullWidth size="large" color="inherit" variant="outlined">
                                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
                            </Button>
                        </Stack>

                        <Divider sx={{ my: 3 }}>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                OR
                            </Typography>
                        </Divider>

                        <Stack spacing={3}>
                            <TextField name="name" label="Name" onChange={(e) => setData({ ...data, name: e.target.value })} />

                            <FormControl fullWidth>
                                <InputLabel >Entity Type</InputLabel>
                                <Select
                                    value={data.type}
                                    label="Entity Type"
                                    name="type"
                                    onChange={(e) => {
                                        setData({ ...data, type: e.target.value })
                                    }}
                                >
                                    <MenuItem value={"university"}>University</MenuItem>
                                    <MenuItem value={"employer"}>Employer</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField name="email" label="Email address" onChange={(e) => setData({ ...data, email: e.target.value })} />

                            <TextField
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => setData({ ...data, password: e.target.value })}
                            />
                            <FormControl
                                variant="contained"
                                component="label"
                            >
                                <Typography variant='subtitle2' sx={{ my: 1 }}>
                                    Upload Entity Logo
                                </Typography>
                                <Input type='file' hidden sx={{ pb: 1 }} onChange={(e) => setSelectedLogo(e.target.files[0])} />
                            </FormControl>

                        </Stack>

                        <LoadingButton
                            sx={{ mt: 3 }}
                            fullWidth size="large" type="submit" variant="contained" onClick={() => { props.handler(data, isloggin, handleIsLogginChange, reset, selectedLogo,) }}>
                            Register
                        </LoadingButton>
                    </StyledContent>
                </Container>}
                {mdUp && (
                    <StyledSection style={{boxShadow:"none"}}>
                        <Typography variant="h3" sx={{ px: 5, mb: 5, mx: "auto" }}>
                            <Grid sx={{ width: "100%" }}>
                                <Logo
                                    sx={{
                                        // top: { xs: 16, sm: 24, md: 40 },
                                        // left: { xs: 16, sm: 24, md: 40 },
                                        // maxWidth: "40px"
                                        // margin: "auto",
                                    }} />
                            </Grid>

                            Hi, Welcome Back
                        </Typography>

                        <img src="/assets/illustrations/illustration_login.png" alt="login" />
                    </StyledSection>
                )}
            </StyledRoot>
        </>
    );
}
